import React from "react";
import DOMPurify from 'dompurify';
import MessageTime from "../MessageTime/MessageTime";

import './MessageText.scss';

function MessageText(props){
  return (
    <div className='message'>
      { typeof props.content === 'object' ? <div className='content'>{ props.content }</div> : <div className='content' dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(props.content, { ADD_ATTR: ['target'] }) }} /> }
      <MessageTime datetime={props.datetime} />
    </div>
  );
}

export default MessageText;