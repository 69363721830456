import React from "react";
import Avatar from "../Avatar/Avatar";
import MessageText from "../MessageText/MessageText";

import './Message.scss'

function Message(props){
  return (
    <rlcb-message class={props.from}>
      <Avatar type={props.from} />
      <MessageText 
        content={props.content} 
        datetime={props.datetime}
      />
    </rlcb-message>
  );
}

export default Message;