import React from "react";

import GlobalContext from "../GlobalContext";
import './LetterCount.scss';

function LetterCount(){
  
  const enabled = true;
  const global = React.useContext(GlobalContext);

  const countText = global.state.letterCount !== null && enabled ? <rlcb-letter-count>{global.state.letterCount + '/8000'}</rlcb-letter-count> : false;

  return countText;
}

export default LetterCount;