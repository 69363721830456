import React, { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import DateTimeDivider from "../DateTimeDivider/DateTimeDivider";
import GlobalContext from "../GlobalContext";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import Message from "../Message/Message";

import './MessageList.scss';

import avatar from './../../images/avatar-137.png';
//import avatar from './../../images/LBF-Agent.png';
//import avatar from './../../images/far-logo.png';

const MessageList = () => {

  const listRef = useRef();
  const scrollRef = useRef();
  const global = React.useContext(GlobalContext);

  const { messageHistory } = global.state;

  useEffect(() => {
    if(scrollRef.current){
      scrollRef.current.scrollToBottom();
    }
  }, [messageHistory]);

  let messageListClass = "flex";
  if(messageHistory.length > 0){
    messageListClass += " column flex-start";
  } else {
    messageListClass += " center align-center empty";
  }

  let datetimeDividers = [];
  let messages = [];
  messageHistory.forEach((message, index) => {
    if(message.content !== ''){
      const datetime = [
        message.datetime.getFullYear(),
        message.datetime.getMonth(),
        message.datetime.getDay()
      ].join('-');
      if(!datetimeDividers.includes(datetime))
      {
        datetimeDividers.push(datetime)
        messages.push(
          <DateTimeDivider 
            key={'divider-'+message.datetime}
            datetime={message.datetime}
          />
        )
      }
      messages.push(
        <Message 
          key={'msg' + index}
          from={message.from}
          datetime={message.datetime}
          content={message.content}
        />
      );
    }
  });

  if(messages.length === 0){
    messages.push(
      <div key='placeholder' className='empty'>
        <img src={avatar} alt='Luna avatar' height='137' width='137' />
      </div>
    );
  } else if(global.state.loading){
    messages.push(
      <Message 
        key={'msg-loading'}
        from={'bot'}
        content={<LoadingIndicator />}
      />
    )
  }

  return (
      <message-list 
        ref={listRef}
        class={messageListClass}>
        <Scrollbars ref={scrollRef}>{messages}</Scrollbars>
      </message-list>
  );
}

export default MessageList;