import React from "react";
import { FaRegTrashAlt } from 'react-icons/fa';

import GlobalContext from "../GlobalContext";

import './ClearMessageHistory.scss';

export default function ClearMessageHistory(){

  const global = React.useContext(GlobalContext);

  return (
    <button 
      id="rlcb-close"
      onClick={global.actions.clearMessageHistory}
      >
      <FaRegTrashAlt 
        size='1.5em'  
      />
    </button>
  );
}