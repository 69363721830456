import React from "react";

import ClearMessageHistory from "../ClearMessageHistory/ClearMessageHistory";

import './ChatHeader.scss';

const ChatHeader = () => {

  return (
    <header>
      <ClearMessageHistory />
    </header>
  );
}

export default ChatHeader;