import React, { useEffect, useRef } from "react";

import Scrollbars from "react-custom-scrollbars-2";

import GlobalContext from "../GlobalContext";
import SendButton from "../SendButton/SendButton";

import './MessageEntry.scss';
import LetterCount from "../LetterCount/LetterCount";
import MessageEntryError from "../MessageEntryError/MessageEntryError";

const MessageEntry = props => {

  const global = React.useContext(GlobalContext);

  const scrollRef = useRef();

  const { message, messageHistory } = global.state;

  useEffect(() => {
    if(scrollRef.current){
      scrollRef.current.scrollToBottom();
    }
  }, [message]);

  return (
    <message-entry>
      <expandable-textarea class={global.state.message?.length > 0 ? '' : 'placeholder'}>
      <Scrollbars 
        ref={scrollRef}>
        <text-area
          ref={ global.refs.textareaRef }
          contentEditable={ !global.state.responding }
          key={'entry-' + messageHistory.length}
          onInput={ global.actions.changeMessage } 
          onKeyUp={ global.actions.detectReturn }
          onPaste={ global.actions.processPaste }
          tabIndex={ 1 }
        />
      </Scrollbars>
      </expandable-textarea>
      <SendButton />
      <LetterCount />
      <MessageEntryError />
    </message-entry>
  );
}

export default MessageEntry;