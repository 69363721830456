import React from "react";
import { FaPaperPlane } from 'react-icons/fa';

import GlobalContext from "../GlobalContext";

import './SendButton.scss';

function SendButton(){

  const global = React.useContext(GlobalContext);
  const disabled = global.state.message?.length > 0 ? '' : 'disabled';

  return (
    <button 
      id="rlcb-send" 
      className={ disabled ? 'disabled' : '' }
      onClick={ global.actions.submitMessage }>
      <FaPaperPlane 
        size='1.4em'
      />
    </button>
  );
}

export default SendButton;